<template>
  <div>
    <v-card>
      <v-card-title class="primary white--text text-h5">
        Manage Role
      </v-card-title>
      <v-row class="pa-4" justify="space-between">
        <v-col cols="12" md="5">
          <v-treeview :active.sync="active" :items="items" :load-children="fetchUsers" :open.sync="open" activatable
                      color="warning" open-on-click transition>
            <template v-slot:prepend="{ item }">
              <v-icon v-if="!item.children">
                mdi-account
              </v-icon>
            </template>
            <template v-slot:label="{ item }">
              <div class="d-flex align-center justify-space-between">
                <span>{{ item.name }}</span>
                <v-icon v-if="!item.children" color="red"
                        @click="showDeleteConfirmationDialog(item)">
                  mdi-delete
                </v-icon>
              </div>
            </template>
          </v-treeview>
        </v-col>

        <v-divider vertical />

        <v-col class="d-flex text-center">
          <v-scroll-y-transition mode="out-in">
            <div v-if="!selected" class="text-h6 grey--text text--lighten-1 font-weight-light"
                 style="align-self: center;">
              Select a roles
            </div>
            <v-card v-else :key="selected.id" class="pt-6 mx-auto" flat max-width="500" width="100%">
              <v-card-text>
                <v-card class="d-flex flex-row align-center justify-center">
                  <h3 class="text-h5 mb-0 mr-3">
                    {{ selected.name }}
                  </h3>
                  <v-btn color="primary" text fab small @click.prevent="editRole(selected)">
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-card>
              </v-card-text>
              <h3 class=" subheading font-weight-bold text-left">
                Menus
              </h3>
              <!-- <v-divider></v-divider> -->
              <v-container class="pt-6 px-0 pb-0">
                <v-row align="center" justify="start">
                  <v-col v-for="(selection, i) in selections" :key="selection.text" class="shrink">
                    <v-chip :disabled="loading" close @click:close="selectedMenu.splice(i, 1)">
                      <v-icon left v-text="selection.icon" />
                      {{ selection.text }}
                    </v-chip>
                  </v-col>

                  <v-col v-if="!allSelected" cols="12" class="pt-0">
                    <v-text-field ref="search" v-model="search" full-width hide-details label="Search" class="pt-0"
                                  single-line />
                  </v-col>
                </v-row>
              </v-container>

              <v-list>
                <template v-for="item in categories">
                  <v-list-item v-if="!selectedMenu.includes(item)" :key="item.text" :disabled="loading"
                               @click="selectedMenu.push(item)">
                    <v-list-item-avatar>
                      <v-icon :disabled="loading" v-text="item.icon" />
                    </v-list-item-avatar>
                    <v-list-item-title v-text="item.text" class="text-left" />
                  </v-list-item>
                </template>
              </v-list>

              <v-divider />

              <v-card-actions>
                <v-spacer />
                <v-btn :loading="loading" color="primary" large text @click="save(selected.id)">
                  save
                </v-btn>
              </v-card-actions>
              <!-- permissions view -->
              <ManagePermissions :prop-value="selected.id.toString()" :action-permissions="actionPermissions" :current-server="currentServer" :active="this.active" :users="this.users" />
            </v-card>
          </v-scroll-y-transition>
        </v-col>
      </v-row>
      <v-snackbar v-model="snackbar" :timeout="timeout" :color="snackbarColor" outlined>
        {{ snackbarMessage }}

        <template v-slot:action="{ attrs }">
          <v-btn :color="snackbarColor" text v-bind="attrs" @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
      <v-dialog v-model="dialog" persistent max-width="500px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit Role</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form>
                <v-row>
                  <v-text-field v-model="roleName" label="Role Name*" required />
                </v-row>
              </v-form>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialog = false; roleId = null">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="updateRole()" :loading="btnLoading">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-row justify="center">
        <v-dialog v-model="dialogForGroup" persistent max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5">Are you sure you want to delete {{ this.deleteActionRole.name ? this.deleteActionRole.name : '' }} role </span>
            </v-card-title>

            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text @click="dialogForGroup = false; showPopup = true">
                No
              </v-btn>
              <v-btn color="success" @click="deleteRole()">
                Yes
              </v-btn>
            </v-card-actions>
            <template>
              <!-- <PopupDialog :showPopup=true :message="popupMessage" @confirm="handleConfirm" /> -->
            </template>
          </v-card>
        </v-dialog>


        <!--  showing modal -->

        <v-dialog v-model="dialogForGroupError" persistent max-width="650px">
          <v-card>
            <v-card-title>
              <span class="text-h5"> {{ this.errorMessage ? this.errorMessage : '' }} </span>
            </v-card-title>

            <v-card-actions>
              <v-spacer />
              <v-btn color="error" text @click="dialogForGroupError = false; showPopup = true">
                Ok
              </v-btn>
              <!-- <v-btn color="success" @click="deleteRole()">
              Yes
            </v-btn> -->
            </v-card-actions>
            <template>
              <!-- <PopupDialog :showPopup=true :message="popupMessage" @confirm="handleConfirm" /> -->
            </template>
          </v-card>
        </v-dialog>
      </v-row>

      <PopupDialog :show-popup="showPopup" :message="errorMessage" @confirm="handleConfirm" />
    </v-card>
  </div>
</template>
<script>

import ManagePermissions from './ManagePermissions.vue'

export default {


  components: {
    ManagePermissions,
  },
  data: () => ({
    active: [],
    open: [],
    users: [],
    settings: [],
    // this area is for menu selection 
    menu: [
      {
        id: 1,
        text: 'User List',
        icon: 'mdi-account-group-outline',
        path: '/skyfi-dashboard/user-list',
        component: 'UserList.vue',
      },
      {
        id: 2,
        text: 'Business',
        icon: 'mdi-license',
        path: '/business/:id',
        component: 'Business.vue',
      },
      {
        id: 3,
        text: 'All Business',
        icon: 'mdi-license',
        path: '/business',
        component: 'Business.vue',
      },
      {
        id: 4,
        text: 'Accounts',
        icon: 'mdi-account-key-outline',
        path: '/skyfi-dashboard/accounts/:id',
        component: 'Accounts.vue',
      },
      {
        id: 5,
        text: 'All Accounts',
        icon: 'mdi-account-key-outline',
        path: '/skyfi-dashboard/accounts',
        component: 'Accounts.vue',
      },
      {
        id: 6,
        text: 'Cards',
        icon: 'mdi-cards-outline',
        path: '/skyfi-dashboard/cards/:id',
        component: 'Cards.vue',
      },
      {
        id: 7,
        text: 'All Cards',
        icon: 'mdi-cards-outline',
        path: '/skyfi-dashboard/cards',
        component: 'Cards.vue',
      },
      {
        id: 8,
        text: 'Transactions',
        icon: 'mdi-cash-multiple',
        path: '/skyfi-dashboard/transactions/:id',
        component: 'Transactions.vue',
      },
      {
        id: 9,
        text: 'All Transactions',
        icon: 'mdi-cash-multiple',
        path: '/skyfi-dashboard/transactions',
        component: 'Transactions.vue',
      },
      {
        id: 10,
        text: 'Contacts',
        icon: 'mdi-archive-outline',
        path: '/skyfi-dashboard/contacts/:id',
        component: 'Contacts.vue',
      },
      {
        id: 11,
        text: 'All Contacts',
        icon: 'mdi-archive-outline',
        path: '/skyfi-dashboard/contacts',
        component: 'Contacts.vue',
      },
      {
        id: 12,
        text: 'Network Files',
        icon: 'mdi-web',
        path: '/skyfi-dashboard/network-files',
        component: 'NetworkFiles.vue',
      },
      {
        id: 13,
        text: 'Sameday Info',
        icon: 'mdi-web',
        path: '/skyfi-dashboard/sameday-info',
        component: 'SamedayData.vue',
      },
      {
        id: 14,
        text: 'Reporting',
        icon: 'mdi-web',
        path: '/skyfi-dashboard/reporting',
        component: 'Reporting.vue',
      }
    ],
    loading: false,
    search: '',
    selectedMenu: [],
    // end here
    snackbar: false,
    timeout: 2000,
    snackbarMessage: '',
    snackbarColor: '',
    roleName: '',
    dialog: false,
    roleId: null,
    btnLoading: false,
    deleteActionRole: '',
    deleteActionRoleId: '',
    dialogForGroup: '',
    dialogForGroupError: '',
    showPopup: false,
    popupMessage: '',
    actionPermissions: [
      {
        id: 1,
        text: 'Change Permission Group',
        icon: 'mdi-account-group-outline',
      },
      {
        id: 2,
        text: 'Unlock Accounts',
        icon: 'mdi-account-group-outline',
      },
      {
        id: 3,
        text: 'Reset Password',
        icon: 'mdi-account-group-outline',
      },
      {
        id: 4,
        text: 'Lock Accounts',
        icon: 'mdi-account-group-outline',
      },
    ]

  }),
  computed: {
    items() {
      return [
        {
          name: 'Roles',
          children: this.users,
        },
      ]
    },
    selected() {
      if (!this.active.length) return undefined
      const id = this.active[0];
      const selectedItem = this.users.find(user => user.id === id)
      return selectedItem
    },

    // this area is for menu selection and edit menus 
    allSelected() {
      return this.selectedMenu.length === this.menu.length
    },
    categories() {
      const search = this.search.toLowerCase()

      if (!search) return this.menu

      return this.menu.filter(item => {
        const text = item.text.toLowerCase()
        return text.indexOf(search) > -1
      })
    },
    selections() {
      const selections = []

      for (const selection of this.selectedMenu) {
        selections.push(selection)
      }

      return selections
    },

    rollId: 'Data new' // Data to be passed to child component
    // end here
  },
  watch: {
    selected(newSelected) {
      if (newSelected) {
        const filteredObjects = this.menu.filter(obj1 => {
          return newSelected.menus.some(obj2 => obj2.id === obj1.id);
        });
        this.selectedMenu = filteredObjects;
      } else {
        this.selectedMenu = [];
      }
    },
    selectedMenu() {
      this.search = ''
    },
  },
  props: {
    currentServer: Object,
  },
  methods: {
    openPopup(message) {
      this.message = message;
      this.showPopup = true;
    },
    handleConfirm() {
      this.showPopup = false;
    },
    async fetchUsers(item) {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/roles`, {
          method: 'GET',
          'Accept': 'application/json',
          headers: myHeaders,
          'Content-Type': 'application/json',
          'redirect': 'follow',
        })

        const data = await response.json();
        this.data = JSON.stringify(data);
        // Handle successful login here
        const TableData = data?.map((item) => {
          return {
            id: item?.id,
            name: item?.name,
            menus: item?.menus,
            action_permissions: item?.action_permissions
          }
        })
        item.children.splice(0);
        item.children.push(...TableData)
        this.action_permissions = data
      } catch (error) {
        this.data = error.message
        if (error.message.toUpperCase() === 'Unexpected end of JSON input'.toUpperCase()) {
          this.$router.push('/admin-login');
        }
      }
    },
    async save(id) {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const menuIdArray = this.selectedMenu.map((item) => item.id)
        var raw = JSON.stringify({
          'menu_ids': menuIdArray
        });
        const response = await fetch(`${baseurl}admin/roles/${id}/add_static_menus_to_role`, {
          method: 'POST',
          headers: myHeaders,
          body: raw,
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        if (response.status == '200') {
          await this.fetchUsers(this.items[0])
          this.snackbar = true;
          this.snackbarColor = 'green';
          this.snackbarMessage = 'save Successfully';

        }

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.snackbar = true;
          this.snackbarColor = 'red';
          this.snackbarMessage = error.message;
        }
      }
    },
    async deleteRole() {
      try {
        if (!this.deleteActionRoleId) {
          throw new Error('Invalid role id found');
        }

        const token = localStorage.getItem('token');
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = process.env.VUE_APP_API_BASE_URL

        // Check if any users are assigned to the role
        const usersResponse = await fetch(`${baseurl}admin/roles/${this.deleteActionRoleId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!usersResponse.ok) {
          throw new Error('Failed to fetch users assigned to the role');
        }

        const usersData = await usersResponse.json();

        if (usersData.length > 0) {
          // Users are assigned to this role. Show confirmation dialog.
          this.showDeleteConfirmationPopup = true;
          setTimeout(() => {
            this.showDeleteConfirmationPopup = false;
          }, 5000);

          return;
        }

        // No users are assigned to the role, proceed with deletion
        const deleteResponse = await fetch(`${baseurl}admin/roles/${this.deleteActionRoleId}`, {
          method: 'DELETE',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });


        if (!deleteResponse.ok) {
          const errorResponse = await deleteResponse.json();
          throw new Error(errorResponse?.error);
        }
        // this.showPopup = true;
        this.dialogForGroup = false

        await this.fetchUsers(this.items[0]);
        console.log('Role deleted successfully');

      } catch (error) {
        this.dialogForGroup = false
        console.error('Error deleting role:', error);

        setTimeout(() => {
          this.dialogForGroupError = true;
        }, 100);
        this.errorMessage = error.message;
      }
    },

    editRole(roleData) {
      this.roleId = roleData.id;
      this.roleName = roleData.name;
      this.dialog = true;
    },
    async updateRole() {
      try {
        this.btnLoading = true
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
        const baseurl = process.env.VUE_APP_API_BASE_URL
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        var raw = JSON.stringify({
          'name': this.roleName
        });
        const response = await fetch(`${baseurl}admin/roles/${this.roleId}`, {
          method: 'PUT',
          headers: myHeaders,
          body: raw,
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        if (response.status == '200') {
          await this.fetchUsers(this.items[0])
          this.snackbar = true;
          this.snackbarColor = 'green';
          this.snackbarMessage = 'save Successfully';
          this.btnLoading = false
          this.dialog = false
        }

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.snackbar = true;
          this.snackbarColor = 'red';
          this.snackbarMessage = error.message;
          this.btnLoading = false
        }
      }
    },

    showDeleteConfirmationDialog(data) {
      this.deleteActionRole = data;
      this.deleteActionRoleId = data.id;
      this.dialogForGroup = true;
    },
  },

}
</script>
